<template>
  <div
    style="width: 100%; height: 100%; text-align: center; padding-top: 100px"
    v-if="state.loaderEnabled"
  >
    <div class="lds-ring"><img src="media/logos/Logo-Symbole-couleur.svg"/><div></div><div></div><div></div><div></div></div>
  </div>
  <transition name="fade">
    <div class="card" v-if="!state.loaderEnabled">
      <div class="card-header border-0 pt-6">
        <SearchAndFilter
          :listInitial="state.initialMyList"
          :filters="tableFilters"
          @returnSearch="state.myList = $event"
          @returnLoadingDatatable="state.loadingDatatable = $event"
        ></SearchAndFilter>
      </div>
      <div class="card-body pt-0">
        <div
          v-if="state.myList.length == 0"
          class="alert bg-light-primary alert-primary d-flex align-items-center p-5 mb-10"
        >
          <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
            <inline-svg src="media/icons/duotune/general/gen048.svg" />
          </span>
          <div class="d-flex flex-column">
            <h4 class="mb-1 text-dark">{{$t("Aucune ligne à afficher")}}</h4>
            <span
              >{{$t("Il n'existe aucune ligne à afficher correspondant à vos critères de recherche.")}}</span
            >
          </div>
        </div>
        <Datatable
          v-if="state.myList && state.myList.length > 0"
          :table-data="state.myList"
          :table-header="tableHeader"
          :enable-items-per-page-dropdown="true"
          :rows-per-page="50"
          :empty-table-text="$t('Aucune offre à afficher.')"
          :key="state.loadingDatatable"
          class="table-small-td"
        >
          <template v-slot:cell-liv_seq="{ row: data }">
              <a v-if="isRefMajeur(data)" @click="openSubItem(data)" class="btn btn-sm btn-icon btn-light-primary" style="width: calc(0.5em + 1.1rem + 2px); height: calc(0.5em + 1.1rem + 2px);">
                <i v-if="data.opensub==0" class="fas fa-plus fs-8 me-0"></i>
                <i v-if="data.opensub==1" class="fas fa-minus fs-8 me-0"></i>
              </a>
          </template>

          <template v-slot:cell-loc_designation="{ row: data }">
            <div :class="{ 'decSubItem': !isRefMajeurLN(data) }" class="text-gray-800">
              <!-- 
              <div class="text-gray-400">
                <span class="w-150px fs-8">{{ data.lof_seq }}  -   {{ data.lof_knum_ligne_groupe }}  -  {{ data.lof_x_reference }}  </span>
              </div>
              -->

              <div class="text-gray-400">
                <span class="fs-6 text-dark">{{ data.loc_reference }}</span>
              </div>
              <span class="fs-7 text-gray-500">&nbsp;{{ data.loc_designation }}</span>
            </div>
          </template>
          <template v-slot:cell-liv_qte="{ row: data }">
            <span class="text-primary">{{ data.liv_qte }}</span>
          </template>
          <template v-slot:cell-liv_date_liv="{ row: data }">
            <span v-if="!data.liv_date_liv && data.liv_qte==null">
            </span>
            <span v-else-if="!data.liv_date_liv">
              <div class="badge badge-light-danger w-175px bt-5 text-danger">
                {{$t("Date non communiquée")}}
              </div>
            </span>
            <div v-else>
              <el-popover
                placement="top-start"
                :title="$t('Date de livraison')"
                :width="300"
                trigger="hover"
                :content="formatHoverDateD(data)"
              >
                <template #reference>
                  <span
                    class="badge badge-transparent w-175px fs-6 text-gray-900"
                    style="cursor: help"
                    >{{ formatDate(data.liv_date_liv) }}

                    <span
                      class="svg-icon svg-icon-3 svg-icon-success ms-1"
                      v-if="
                        data.liv_date_old_liv &&
                        data.liv_date_old_liv > data.liv_date_liv
                      "
                    >
                      <inline-svg src="media/icons/duotune/arrows/arr066.svg" />
                    </span>

                    <span
                      class="svg-icon svg-icon-3 svg-icon-danger ms-1"
                      v-if="
                        data.liv_date_old_liv &&
                        data.liv_date_old_liv < data.liv_date_liv
                      "
                    >
                      <inline-svg src="media/icons/duotune/arrows/arr065.svg" />
                    </span>
                  </span>
                </template>
              </el-popover>
              <br />
              <div class="badge badge-light text-gray-600 w-175px bt-5">
                {{ formatDateAgo(data.liv_date_maj) }}
              </div>
            </div>
          </template>
          <template v-slot:cell-liv_m_status="{ row: data }">
            <span
              class="mt-2 mb-2 btn bg-white btn-sm btn-text-dark  w-200px"
              style="cursor: default"
              v-if="data.liv_m_status == 'Livré'"
            >
              <span class="svg-icon svg-icon-1 svg-icon-success">
                <inline-svg src="media/icons/duotune/arrows/arr085.svg" />
              </span>
              {{ data.liv_m_status && $t(data.liv_m_status) }}
            </span>
            <span
              class="me-2 mb-2 btn bg-light-primary btn-sm btn-text-dark w-200px"
              style="cursor: default"
              v-else-if="data.liv_m_status == 'En Stock Stillnetwork'"
            >
              <span class="svg-icon svg-icon-1 svg-icon-success">
                <inline-svg src="media/icons/duotune/arrows/arr085.svg" />
              </span>
              {{$t("En Stock Stillnetwork")}}
            </span>
            <span
              class="me-2 mb-2 btn bg-light-primary btn-sm btn-text-dark w-200px"
              style="cursor: default"
              v-else-if="data.liv_m_status == 'En Stock Entrepot client'"
            >
              📦 {{$t("En Stock Entrepot client")}}
            </span>
            <span
              class="me-2 mb-2 btn bg-light-warning btn-sm btn-text-dark  w-200px"
              style="cursor: default"
              v-else
            >
              {{ data.liv_m_status && $t(data.liv_m_status) }}
            </span>
          </template>
          <template v-slot:cell-sn_tot="{ row: data }">
            <a
              :test="data"
              class="
                btn btn-sm btn-active-primary
                mt-2
                mb-2
                btn-bg-white btn-text-dark
              "
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_2"
              @click="openSN(data)"
              v-if="data.sn_tot > 0"
            >
              <span class="svg-icon svg-icon-1 svg-icon-dark">
                <inline-svg src="media/icons/duotune/text/txt009.svg" />
                {{$t("Numéros de série")}}
              </span>
            </a>
            <a
              :test="data"
              class="btn btn-sm btn-active-primary mb-2 btn-bg-white btn-text-dark"
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_2"
              v-if="data.liv_date_liv"
              @click="openHisto(data)"
            >
              <span class="svg-icon svg-icon-1 svg-icon">
                <inline-svg src="media/icons/duotune/general/gen014.svg" />
                {{$t("Historique dates")}}
              </span>
            </a>
          </template>
          <!--
          <template v-slot:cell-tof_code="{ row: data }">
            <span class="text-primary">{{ data.tof_code }}</span>
          </template>
          <template v-slot:cell-tof_date_creation="{ row: data }">
            {{ formatDate(data.tof_date_creation) }}
          </template>

          <template v-slot:cell-tof_status="{ row: data }">
            <span
              class="me-2 mb-2 btn btn-bg-light btn-sm btn-text-dark"
              :class="styleBtnStatus(data.tof_status)"
              style="cursor: default"
            >
              <span class="svg-icon svg-icon-1">
                <inline-svg :src="styleImgStatus(data.tof_status)" />
              </span>
              {{ data.tof_status }}
            </span>
          </template>
          <template v-slot:cell-tof_total_ht="{ row: data }">
            <div>
              <span class="text-gray-400"
                >{{ formatMoney(data.tof_total_ht) }} {{ data.dev_sigle }}</span
              >
              <br />
              <strong class="text-gray-700"
                >{{ formatMoney(data.tof_total_ttc) }}
                {{ data.dev_sigle }}</strong
              >
            </div>
          </template>
          <template v-slot:cell-tof_seq="{ row: data }">
            <a
              :test="data"
              class="btn btn-sm btn-light-primary me-2 mb-2"
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_2"
              @click="OffrePDF(data)"
            >
              <span class="svg-icon svg-icon-1">
                <i class="far fa-file-pdf fs-2"></i> </span
              >Offre PDF</a
            >
          </template>
          -->
        </Datatable>
      </div>
    </div>
  </transition>
  <el-drawer
    v-model="state.drawer"
    title=""
    :with-header="false"
    size="450px"
    modal-class="modal-offre"
  >
    <div class="card shadow-none" style="width: 100%; height: 100%">
      <!--begin::Header-->
      <div class="card-header" id="kt_activities_header">
        <h3 class="card-title fw-bolder text-dark">{{$t("Numéros de série")}}</h3>
        <div class="card-toolbar">
          <button
            type="button"
            class="btn btn-sm btn-icon btn-active-light-primary me-n5"
            @click="state.drawer = false"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="media/icons/duotune/arrows/arr061.svg" />
            </span>
          </button>
        </div>
      </div>
      <!--end::Header-->

      <!--begin::Body-->
      <div class="card-body position-relative" id="kt_activities_body">
        <h5>{{$t("Concerne l'équipement :")}}<br /></h5>
        <strong>{{ state.mObj.loc_designation }}</strong>
        <div class="py-5">
          <div class="d-flex flex-column">
            <li
              class="d-flex align-items-center py-2"
              v-for="item in state.lSN"
              :key="item.snb_seq"
            >
              <span class="bullet bg-primary"></span>&nbsp;{{ item.snb_serial }}
            </li>
          </div>
        </div>
      </div>
      <!--end::Body-->
      <!--end::Footer-->
    </div>
  </el-drawer>
  <el-drawer  v-model="state.drawerHisto"    title=""     :with-header="false"     size="450px"    modal-class="modal-offre"  >
    <div class="card shadow-none" style="width: 100%; height: 100%">
      <!--begin::Header-->
      <div class="card-header" id="kt_activities_header">
        <h3 class="card-title fw-bolder text-dark">{{$t("Historique dates de livraison")}}</h3>
        <div class="card-toolbar">
          <button
            type="button"
            class="btn btn-sm btn-icon btn-active-light-primary me-n5"
            @click="state.drawerHisto = false"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="media/icons/duotune/arrows/arr061.svg" />
            </span>
          </button>
        </div>
      </div>
      <!--end::Header-->

      <!--begin::Body-->
      <div class="card-body position-relative" id="kt_activities_body">
        <h5>{{$t("Concerne l'équipement :")}}<br /></h5>
        <strong>{{ state.mObj.loc_designation }}</strong>
        <div class="py-5">
          <div class="d-flex flex-column">
            <li
              class="d-flex align-items-center py-2"
              v-for="item in state.datesHisto"
              :key="item.ldc_seq"
            >
              <span class="bullet bg-primary"></span>&nbsp;<b>{{$t("Livraison le")}} {{ formatDate(item.ldc_date) }}</b>&nbsp;<span v-if="item.ldc_date_modification">{{$t("mise à jour le")}} {{ formatDate(item.ldc_date_modification) }}</span>
            </li>
          </div>
        </div>
      </div>
      <!--end::Body-->
      <!--end::Footer-->
    </div>
  </el-drawer>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, reactive } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as moment from "moment";
import { useRouter } from "vue-router";
import SearchAndFilter from "@/views/still/search_and_filters.vue";
import { useI18n } from "vue-i18n";

import mAxiosApi from "@/api";

export default defineComponent({
  name: "Détail d'une commande | Livraisons",
  components: {
    Datatable,
    SearchAndFilter,
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      loaderEnabled: true,
      myList: [] as any,
      drawer: ref(false),
      drawerHisto: ref(false),
      datesHisto: [],
      sourcePDF: "",
      mOffre: "",
      initialMyList: [],
      loadingDatatable: 0,
      myListLiv: [],
      lSN: [],
      mObj: {},
    });
    const { t } = useI18n()

    const tableHeader = ref([
      {
        name: "",
        key: "liv_seq",
        sortable: false,
      },
      {
        name: "Référence / Désignation",
        key: "loc_designation",
        sortable: false,
      },
      {
        name: "Qté",
        key: "liv_qte",
        sortable: true,
      },
      {
        name: "Livraison",
        key: "liv_date_liv",
        sortable: true,
      },
      {
        name: "Statut",
        key: "liv_m_status",
        sortable: true,
      },
      {
        name: "Actions",
        key: "sn_tot",
        sortable: true,
      },
    ]);

    const tableFilters = ref([
      {
        name: 'Statut',
        key: "liv_m_status",
        label: "liv_m_status",
      },
    ]);

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };
    const formatDateAgo = (d) => {
      return moment.default(d, "YYYY-MM-DD").fromNow();
    };
    const formatMoney = (n) => {
      return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };

    const styleBtnStatus = (status) => {
      if (status == "Offre validée") return "btn-icon-success";
      if (status == "Offre déclinée") return "btn-icon-danger";
      return "btn-icon-info";
    };

    const styleImgStatus = (status) => {
      if (status == "Offre validée")
        return "media/icons/duotune/general/gen048.svg";
      if (status == "Offre déclinée")
        return "media/icons/duotune/general/gen050.svg";
      return "media/icons/duotune/general/gen049.svg";
    };

    const openSN = async (data) => {
      const resultAdr = await mAxiosApi.prototype.getAxios("/getSN/" + data.liv_seq);

      state.lSN = resultAdr.results;
      state.mObj = data;
      state.drawer = true;
    };


    const openHisto = async (data) => {
      const resultAdr = await mAxiosApi.prototype.getAxios("/getHistDates/" + data.liv_seq);
      state.datesHisto = resultAdr.results;
      state.mObj = data;
      state.drawerHisto = true;
    };

    const formatHoverDateD = (data) => {
      if (!data.liv_date_old_liv) {
        return `${t("La date de livraison n'a connue aucune variation depuis le")} ${formatDate(
          data.toc_date_creation
        )} `;
      }
      return `${formatDateAgo(
        data.liv_date_maj
      )}, ${t("la date de livraison est passée du")} ${formatDate(
        data.liv_date_old_liv
      )} ${t("au")} ${formatDate(data.liv_date_liv)}`;
    };

    const isRefMajeur = (d) => {
      const listTmp = state.myList.filter(
        // .lof_knum_ligne_groupe === d.lof_seq
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (item: any) => item.lof_knum_ligne_groupe === d.liv_seq  
      );
      return listTmp.length > 0;
    }

    const isRefMajeurLN = (d) => {
      console.log("Ref MAJ", d);
      if (d.lof_line_number) {
        return d.lof_line_number.indexOf('.0') > 0;
      } else {
        return true;
      }
      
    }

    const openSubItem = (d) => {
      state.myList.forEach(el => {         
          if (el.liv_seq === d.liv_seq) {
            el.opensub = d.opensub == 0 ? 1 : 0;
          }
          if (el.lof_knum_ligne_groupe === d.liv_seq) {
            el.displayrow = d.opensub == 0 ? 1 : 2;
          }

        });
    }


    onMounted(async () => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Détail d'une commande", [
        "Home",
        "Board Stillnetwork",
      ]);

      const codeToc = router.currentRoute.value.params.code as string;
      let myListLiv = await mAxiosApi.prototype.getAxios("/getLivraisons/" + codeToc);

      state.myList = myListLiv.results;
      let curMajeur = 0;


      state.myList.forEach(el => {
        if( el.lof_line_number && (el.lof_line_number.indexOf('.') ==  el.lof_line_number.lastIndexOf('.')) && (el.lof_line_number.indexOf('.0') > 0 ) ) {
          curMajeur = el.liv_seq;
          el.opensub = 0
          el.displayrow = 1
        } else if (el.lof_line_number && el.lof_line_number.length >0 && curMajeur>0 ) {
          el.lof_knum_ligne_groupe = curMajeur;
          el.displayrow = 2
        }
        // console.log(el.liv_seq, el.lof_knum_ligne_groupe)
      });

      
      /*
      state.myList.forEach(el => {  
        if (el.lof_knum_ligne_groupe > 0) {
          const listTmp = state.myList.filter(
            (item: any) => item.lof_seq === el.lof_knum_ligne_groupe  
          );
          if (listTmp.length==0) el.displayrow = 0;
        }


      });  

      */

      state.initialMyList = state.myList;
      state.loaderEnabled = false;
    });

    return {
      tableHeader,
      state,
      formatDate,
      formatDateAgo,
      formatHoverDateD,
      formatMoney,
      styleBtnStatus,
      styleImgStatus,
      openSN,
      openHisto,
      tableFilters,
      isRefMajeur,
      isRefMajeurLN,
      openSubItem,
    };
  },
});
</script>
